.about-company-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin: 50px 100px;
}
.about-company-img-container {
  /* width: 40%;  */
  height: auto;
  position: relative;
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.about-comapny-main-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.about-compny-sec-img {
  /* width: 100%; */
  width: 450px;
  position: absolute;
  height: auto;
  top: 0;
  border-radius: 20px;
}
.behind-card {
  background-color: var(--mainBackgroundColor);
  top: 0;
  /* width: 100%; */
  height: 450px;
  width: 450px;
  margin-top: 50px;
  margin-left: 50px;
  border-radius: 20px;
}
.summary-text-container {
  /* width: 40%; */
  padding: 20px;
}
.summary-text {
  font-size: 20px;
  line-height: 30px;
  color: var(--mainColor);
}



@media screen and (max-width: 1300px) {
  .about-company-info-container {

    margin: 20px;
  }
  .about-comapny-main-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-company-img-container {
    width: 90%;
  }
  .summary-text-container {
    width: 90%;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }
  .summary-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--mainColor);
  }
  .behind-card{
    display: none;
 
  }
  .about-compny-sec-img {
    /* width: 100%; */
    width: 450px;
    position: relative;
    height: auto;
    top: none;
    border-radius: 20px;
  }

}
