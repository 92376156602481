.testimonial-main-container {
  width: 99%;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  margin: 30px 0px;
  justify-content: center;
}
.testimony-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.testimony-main-card {
  width: 30%;
  min-height: 150px;
  margin: 30px 20px;
  border: 4px solid var(--mainBackgroundColor);
  padding: 20px 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mainBackgroundColor);
  position: relative;
}

.testimonial-text {
  font-size: 16px;
  line-height: 30px;
}
.qoute-mark {
  font-size: 30px;
  padding: 0px 5px;
  color: var(--mainBackgroundColor);
}
.client-testimonial-name {
  font-size: 18px;
  font-weight: 800;
  padding: 10px;
}
.qoutes-icon-start {
  width: 80px;
  rotate: 180deg;
  background-color: white;
  margin-top: -38px;
  margin-left: -42px;
}
.qoutes-icon-end {
  width: 80px;
  height: auto;
  rotate: 360deg;
}
.quote-icon-container {
  position: absolute;
  top: 0;
  left: 0;
}
.quote-end-icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -46px;
  margin-right: -40px;
  background-color: white;
}

@media screen and (max-width: 1000px) {
  .testimonial-main-container {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;
    margin:  50px 0px;
    justify-content: center;
}
  .testimony-card-container {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .testimony-main-card {
    height: auto;
    width: 50%;
    margin: 20px;
  }
  
}
