.main-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--mainBackgroundColor);
  height: 40px;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between;
  font-weight: 800;
  position: fixed;
  z-index: 900;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.header-options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 40%;
  color: white;
}
.header-options-container a {
  text-decoration: none;
}
.header-option {
  font-size: 14px;
  color: white;
}
.logo-img {
  width: 130px;
  height: auto;
  padding: 5px;
}
.logo-container {
  display: flex;
  justify-content: flex-start;
}
.responsive-menu-container {
  display: none;
}
.menu-icon-img {
  width: 25px;
  height: auto;
  padding: 10px 20px;
}
.responsive-fixed-sidebar-container {
  display: none;
}
.hide {
  display: none;
}
.show {
  display: flex;
}
@media screen and (max-width: 724px) {
  .main-header-container {
    height: 30px;
  }
  .responsive-menu-container {
    display: flex;
    flex-direction: column;
  }

  .header-options-container {
    display: none;
  }
  .responsive-fixed-sidebar-container {
    display: flex;
    flex-direction: column;
    background-color: var(--mainBackgroundColor);

    width: 100%;
    position: fixed;
    height: 100%;

    justify-content: flex-start;
    align-items: flex-start;
    right: 0;
  }
  .responsive-fixed-sidebar {
    background-color: gray;
    overflow-y: auto;
  }
  .responsive-menu-option {
    padding: 30px 20px;
    color: white;
    text-decoration: none;
  }
  .responsive-fixed-sidebar-container a {
    text-decoration: none;
  }
  .close-icon-sidebar {
    font-size: 20px;
    padding: 20px;
    text-align: right;
    width: 90%;
    color: white;
  }
  .logo-img{
    width: 100px;
  }
}
