.client-sect-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 50px;
  margin: 20px 0px;
  /* background-color: var(--mainBackgroundColor); */
}
.client-logo-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 500px;
}
.logo-img-container {
  min-width: 20%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-logo-img {
  width: 200px;
}
.client-setion-title {
  color: var(--mainColor);
  font-size: 40px;
  font-weight: 500;
  font-family: var(--globalFont-family);
  padding: 20px;
}

@media screen and (max-width: 1000px) {
  .client-sect-container {
    padding: 20px;
    width: 95%;
    justify-content: center;
    align-items: center;
  }
  .client-setion-title {
    font-size: 20px;
  }
}
