.main-form-container {
  border-radius: 5px;
  padding: 20px 50px;
  width: 40%;
}
.main-form-container input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-family: var(--globalFont-Family);
}

.main-form-container input[type="submit"] {
  background-color: white;
  color: var(--mainColor);
  font-weight: 700;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.main-form-container input[type="submit"]:hover {
  border: 1px solid black;
  box-shadow: 1px 1px 3px black;
}

.conatct-form-title {
  color: white;
  padding: 20px 0px;
  font-size: 20px;
}
@media  screen and (max-width: 1000px) {

  .main-form-container {
 
    padding: 20px;
    
    width: 90%;
  }
}