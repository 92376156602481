.about-cards-main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px 100px;
}
.about-service-card-container {
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 25%;
  min-width: 200px;
  height: 200px;
  background-color: var(--mainBackgroundColor);
  margin: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
}

.bottom-card {
  margin-top: 100px;
}

@media screen and (max-width: 1000px) {
  .about-cards-main-container {
    margin: 20px;
  }
}
