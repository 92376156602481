.footer-main-container {
  border-top: 1px solid gray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 50px;
  background-color: var(--mainBackgroundColor);
  color: white;
}
.second-col-footer-conatiner {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0px 50px;
}
.column-third-info {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.company-summary {
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
}
.company-detail-text {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
}

.detail-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.company-detail-section-title {
  margin-top: 14px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
  margin-bottom: 10px;
}
.quick-links {
  font-size: 14px;
  padding: 5px 0px;
}
.copywriite-footer {
  width: 100%;
  background-color: rgb(0, 0, 0);
  padding: 10px;
  color: white;
}
@media screen and (max-width: 1024px) {
  .second-col-footer-conatiner {
    width: 90%;
    padding: 0px;
  }
}
