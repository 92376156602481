body {
  --globalFont-family: "Open Sans", sans-serif;
  margin: 0px;
  --mainBackgroundColor: #317a82;
  --mainColor: #46a18d;
  font-family: var(--globalFont-family);
  scroll-behavior: smooth;
}
.section-title {
  padding: 20px;
  font-size: 40px;
  color: var(--mainColor);
  margin: 10px;
  text-align: center;
  font-weight: 600;
}


@media screen and (max-width: 1024px) 
{
  .section-title {
    font-size: 20px;
  }
}